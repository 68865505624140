<template>
  <div>
    <a :href="link" target="_blank" rel="noopener">
      <inline-svg
        class="align-self-center"
        src="/app-store-logo.svg"
        width="90px"
        aria-label="Loading..."
      />
    </a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      link: "https://apps.apple.com/co/app/wseeds/id1570935280?l=en"
    }
  }
}
</script>
